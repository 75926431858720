/* Home.css */
.home-container {
  min-height: 100vh; /* Change from fixed height to minimum height */
  background-color: #111827;
  color: white;
  overflow-x: hidden; /* Allow vertical scrolling, only prevent horizontal */
  position: relative; 
  width: 100%; 
}

.custom-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: rgba(17, 24, 39, 0.95);
  backdrop-filter: blur(8px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.nav-brand {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.brand-name {
  font-size: 1.5rem;
  font-weight: 600;
  color: white;
}

.hero-section {
  padding: 6rem 0;
  text-align: center;
}

.marketplace-title {
  font-size: 4rem;
  font-weight: bold;
  background: linear-gradient(to right, #fff, #a5b4fc);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1.5rem;
}

.subtitle {
  font-size: 1.5rem;
  color: #a5b4fc;
  margin-bottom: 2rem;
  line-height: 1.4;
}

.button-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.get-started-btn {
  background: linear-gradient(to right, #4f46e5, #7c3aed);
  border: none;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  border-radius: 8px;
  color: white;
  font-weight: 500;
  transition: transform 0.2s;
}

.arrow {
  font-size: 0.9em;
  margin-left: 4px;
}

.get-started-btn:hover {
  transform: translateY(-2px);
  background: linear-gradient(to right, #4338ca, #6d28d9);
}

/* Enhanced HPC Investment Section */
.hpc-investment-section {
  margin-top: 1rem; /* Reduced from 5rem */
  margin-bottom: 1rem; /* Reduced from 3rem */
  padding: 1rem 0; /* Reduced from 3rem */
}

.hpc-investment-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: -50%;
  right: -50%;
  height: 100%;
  background: linear-gradient(90deg, rgba(99, 102, 241, 0.05), rgba(139, 92, 246, 0.1), rgba(99, 102, 241, 0.05));
  z-index: -1;
  transform: skewY(-2deg);
}

.hpc-title {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  background: linear-gradient(90deg, #6366f1, #8b5cf6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 30px rgba(99, 102, 241, 0.3);
  letter-spacing: -0.5px;
}

.hpc-button {
  background: linear-gradient(90deg, #6366f1, #8b5cf6);
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  padding: 1.25rem 2.5rem;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  box-shadow: 0 4px 15px rgba(99, 102, 241, 0.3);
}

.hpc-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 25px rgba(99, 102, 241, 0.4);
  background: linear-gradient(90deg, #5254cc, #7a4ecc);
}

.hpc-button svg {
  transition: transform 0.2s;
}

.hpc-button:hover svg {
  transform: translateX(4px);
}

.servers-title {
  font-size: 2.5rem;
  margin: 3rem 0;
  color: white;
  text-align: center;
}

.partners-section {
  margin: 4rem auto;
  max-width: 800px;  /* Added max-width to constrain the width */
}

.marquee-container {
  overflow: hidden;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  padding: 1.5rem 0;
}

.marquee {
  display: flex;
  animation: scroll 30s linear infinite;  /* Increased animation duration to 30s */
}

.marquee-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-width: 100%;
  gap: 6rem;  /* Increased gap between logos */
  padding: 0 3rem;
}

.partner-logo {
  height: 60px;  /* Increased logo size */
  object-fit: contain;
  filter: grayscale(100%) brightness(1.5);
  transition: filter 0.3s;
}

.partner-logo:hover {
  filter: grayscale(0%) brightness(1);
}

.tagline-section {
  margin: 4rem 0;
  padding: 2rem;
}

.tagline {
  font-size: 2rem;
  line-height: 1.4;
  background: linear-gradient(to right, #60a5fa, #a78bfa);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}

.preview-section {
  margin: 4rem -2rem; /* Negative margin to extend slightly beyond container */
  padding: 0 2rem;
  position: relative;
  overflow: hidden;
}

.sample-image {
  position: relative;
  width: 85%; /* Slightly narrower than full width */
  margin: 0 auto;
}

.sample-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: -5%;
  right: -5%;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: -1;
}

.sample-image img {
  max-width: 100%;
  border-radius: 12px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media (min-width: 1921px) {
  .hero-content {
    padding: 4rem 8rem;
  }

  .marketplace-title {
    font-size: 5rem;
  }

  .subtitle {
    font-size: 2rem;
  }

  .get-started-btn {
    padding: 1.5rem 3rem;
    font-size: 1.4rem;
  }

  .hpc-title {
    font-size: 4.5rem;
  }

  .hpc-button {
    font-size: 1.75rem;
    padding: 1.5rem 3rem;
  }

  .tagline {
    font-size: 2.5rem;
  }

  .partners-section {
    max-width: 1400px;
  }

  .partner-logo {
    height: 80px;
  }
}

@media (max-width: 768px) {
  .hero {
    min-height: auto;
    padding-top: 60px;
  }

  .marketplace-title {
    font-size: 2.5rem;
    padding: 0 1rem;
  }

  .subtitle {
    font-size: 1.2rem;
    padding: 0 1rem;
  }

  .get-started-btn {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
  }

  .hpc-title {
    font-size: 2.5rem;
  }

  .hpc-button {
    font-size: 1.25rem;
    padding: 1rem 2rem;
  }

  .partners-section {
    margin: 2rem auto;
  }

  .marquee-content {
    gap: 2rem;
  }
}

.gradient-button {
  background: linear-gradient(to right, #4f46e5, #7c3aed);
  border: none;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  border-radius: 8px;
  color: white;
  font-weight: 500;
  transition: transform 0.2s;
}

.gradient-button:hover {
  transform: translateY(-2px);
  background: linear-gradient(to right, #4338ca, #6d28d9);
}