/* HardwareDetail.module.css (with additions for the improved dropdown) */
.container {
  padding: 2rem;
  padding-top: 4rem;
  min-height: calc(100vh - 140px);
  background-color: #111827;
  color: #e5e7eb;
}

.buttonContainer {
  text-align: left;
  padding-top: 20%; 
  width: 100%;
  margin-bottom: 20px;
}

.backButton {
  background: rgba(255, 255, 255, 0.9) !important;
  border: none !important;
  padding: 0.5rem 1.5rem !important;
  transition: all 0.2s ease !important;
  color: #111827 !important;
  font-weight: 500 !important;
  margin-bottom: 2rem;
  margin-left: 0;
  position: relative;
  top: 10px;
  left: 5px;
  float: left !important; /* Force left alignment */
  display: block !important; /* Prevent inline centering behavior */
}

.backButton:hover {
  background: rgba(255, 255, 255, 1) !important;
  transform: translateX(-2px);
}

.title {
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #fff;
  text-align: center;
}

.detailCard {
  background: rgba(30, 41, 59, 0.7);
  backdrop-filter: blur(8px);
  border-radius: 12px;
  padding: 2.5rem;
  margin-bottom: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.priceSection {
  background: rgba(17, 24, 39, 0.6);
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.askPrice {
  font-size: 1.4rem;
  font-weight: 600;
  color: #fff;
}

.currentBid {
  font-size: 1.2rem;
  color: #10b981;
  font-weight: 500;
}

.sectionTitle {
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 0.5rem;
  font-size: 1.3rem;
  color: #e5e7eb;
  text-align: left;
}

.specSection, .locationSection, .sellerSection {
  margin-bottom: 2rem;
}

.specsList {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.specRow {
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem 0;
}

.specLabel {
  flex: 1;
  min-width: 180px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
}

.specValue {
  flex: 2;
  color: #fff;
  font-weight: 400;
}

.actionButtons {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  justify-content: center;
}

.bidButton {
  background: linear-gradient(to right, #4f46e5, #7c3aed) !important;
  border: none !important;
  padding: 0.75rem 2rem !important;
  font-size: 1.1rem !important;
  font-weight: 500 !important;
  transition: all 0.2s ease !important;
  color: white !important;
}

.bidButton:hover {
  background: linear-gradient(to right, #4338ca, #6d28d9) !important;
  transform: translateY(-2px);
}

.contactButton {
  padding: 0.75rem 2rem !important;
  font-size: 1.1rem !important;
  border-color: rgba(255, 255, 255, 0.3) !important;
  color: #e5e7eb !important;
}

.contactButton:hover {
  border-color: rgba(255, 255, 255, 0.5) !important;
  color: #fff !important;
}

.notFound {
  text-align: center;
  padding: 3rem;
  background: rgba(30, 41, 59, 0.7);
  border-radius: 12px;
  max-width: 600px;
  margin: 3rem auto;
}

/* Improved dropdown styles */
.filterContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}

.dropdownContainer {
  position: relative;
  width: 250px;
}

.dropdownToggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 16px;
  background-color: #1a2236;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  text-align: left;
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #1a2236;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  z-index: 10;
  max-height: 300px;
  overflow-y: auto;
  margin-top: 4px;
}

.dropdownItem {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.dropdownItem:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.dropdownItem.active {
  background-color: rgba(255, 255, 255, 0.1);
}

.checkmark {
  margin-right: 8px;
  color: #3d8af7;
}

.chevron {
  transition: transform 0.2s;
}

.chevron.open {
  transform: rotate(180deg);
}

@media (min-width: 1921px) {
  .detailCard {
      max-width: 1200px;
      padding: 3rem;
  }

  .title {
      font-size: 3rem;
  }
}

@media (max-width: 768px) {
  .container {
      padding: 1rem;
  }

  .title {
      font-size: 2rem;
  }

  .detailCard {
      padding: 1.5rem;
  }

  .specRow {
      flex-direction: column;
      gap: 0.25rem;
  }

  .actionButtons {
      flex-direction: column;
  }

  .bidButton, .contactButton {
      width: 100%;
  }
}