/* SideNav.module.css */
.sideNav {
  width: 250px;
  min-width: 250px;
  background-color: #1a1f2e;
  border-right: 1px solid #2d3748;
  padding: 110px 0;
  transition: width 0.3s ease;
  position: relative; 
}

.sideNavCollapsed {
  width: 60px;
  min-width: 60px;
}

.navHeader {
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
  padding: 0 20px 20px 20px;
  border-bottom: 1px solid #2d3748;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navItems {
  list-style: none;
  padding: 0;
  margin: 0;
}

.navItem {
  padding: 12px 20px;
  transition: background-color 0.2s;
  white-space: nowrap;
  overflow: hidden;
}

.navItem:hover {
  background-color: #2d3748;
}

.navItem a {
  color: #a0aec0;
  text-decoration: none;
  display: flex;
  align-items: center;
  width: 100%;
}

.navItem.active {
  background-color: #3B82F6;
}

.navItem.active a {
  color: white;
}

.parentItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #a0aec0;
  cursor: pointer;
}

.subItem {
  padding-left: 35px;
  background-color: #141824;
}

.subItem:hover {
  background-color: #242a3b;
}

.toggleButton {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #a0aec0;
  margin-left: 10px;
}

.toggleButton:hover {
  color: white;
}

.toggleIcon {
  transition: transform 0.3s ease;
}

.rotateIcon {
  transform: rotate(180deg);
}

.navIcon {
  margin-right: 10px;
  font-size: 1.2em;
  vertical-align: middle;
}

.expandIcon {
  margin-left: auto;
  font-size: 0.8em;
  transition: transform 0.3s ease;
}

.expanded {
  transform: rotate(0deg);
}

.sideNavCollapsed .navIcon {
  margin-right: 0;
}

.navText {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  line-height: 1.2;
}

.sideNavCollapsed .navText {
  display: none;
}

.sideNavCollapsed .expandIcon {
  display: none;
}