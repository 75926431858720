.dashboardLayout {
  display: flex;
  min-height: calc(100vh - 64px); /* Adjust based on your navbar height */
  background-color: #121826;
}

.mainContent {
  flex: 1;
  overflow-y: auto;
  padding: 0 20px;
}