.container {
  padding: 2rem;
  min-height: calc(100vh - 140px);
  background-color: #111827;
}

.title {
  color: #fff;
  font-size: 2.5rem;
  font-weight: 600;
  padding-top: 2rem;
  margin-bottom: 2rem;
}

.listingsWrapper {
  margin-top: 2rem;
}

.listingsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 2rem;
  padding: 1rem;
}

.cardWrapper {
  display: flex;
  flex-direction: column;
}

@media (min-width: 1921px) {
  .container {
      padding: 3rem;
  }

  .title {
      font-size: 3rem;
  }

  .listingsGrid {
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
      gap: 3rem;
  }
}

@media (max-width: 768px) {
  .container {
      padding: 1rem;
  }

  .title {
      font-size: 2rem;
      padding-top: 1rem;
  }

  .listingsGrid {
      grid-template-columns: 1fr;
      gap: 1rem;
      padding: 0.5rem;
  }
  
  .filtersRow {
      flex-direction: column;
      gap: 0.5rem;
  }
  
  .dropdownContainer {
      width: 100%;
  }
}

/* Dropdown and filter styling */
.filterContainer {
  margin-bottom: 2rem;
}

.filtersRow {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
}

.dropdownContainer {
  position: relative;
  width: 280px;
}

.dropdownToggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.75rem 1rem;
  background-color: #1f2937;
  color: white;
  border: 1px solid #374151;
  border-radius: 0.375rem;
  cursor: pointer;
  font-size: 1rem;
}

.dropdownToggle:hover {
  background-color: #2d3748;
}

.chevron {
  transition: transform 0.2s;
}

.chevron.open {
  transform: rotate(180deg);
}

.dropdownMenu {
  position: absolute;
  top: calc(100% + 0.25rem);
  left: 0;
  right: 0;
  background-color: #1f2937;
  border: 1px solid #374151;
  border-radius: 0.375rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  z-index: 10;
  max-height: 300px;
  overflow-y: auto;
}

.dropdownItem {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  cursor: pointer;
  color: white;
}

.dropdownItem:hover {
  background-color: #2d3748;
}

.dropdownItem.active {
  background-color: #3b82f6;
}

.checkmark {
  margin-right: 0.5rem;
}

/* Additional styles for filter indicators */
.activeFilters {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
}

.filterTag {
  display: flex;
  align-items: center;
  background-color: #3b82f6;
  color: white;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.875rem;
}

.filterTagRemove {
  margin-left: 0.5rem;
  cursor: pointer;
}

/* No results message styling */
.noResults {
  text-align: center;
  color: white;
  background-color: #1f2937;
  padding: 2rem;
  border-radius: 0.5rem;
  margin-top: 2rem;
}

.noResults h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

/* Loading indicator */
.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  color: white;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.1);
  border-left-color: #3b82f6;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 1s linear infinite;
  margin-right: 1rem;
}

@keyframes spin {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

/* Better hover effects for cards */
.cardWrapper:hover {
  transform: translateY(-4px);
  transition: transform 0.2s ease-in-out;
}

/* Focus styles for better accessibility */
.dropdownToggle:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.5);
}

.dropdownItem:focus {
  outline: none;
  background-color: #2d3748;
}